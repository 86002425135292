.time-machine-img-container {
    display: flex;
    justify-content: center;
}

.time-machine-img-container img{
    width: 400px;
    box-shadow: none;
}

