.footer-container {
    max-width: 100%;
    margin: auto;
    padding: 0 16px; 
    padding-top: 15px;
    padding-bottom: 25px;
    font-family: 'Press Start 2P', cursive;
    display: flex;
    justify-content: center;
}

.footer-container .footer-text {
    text-align: center;
    width: 360px;
}

.footer-container .footer-text h2 {
    font-size: 13px;
}

.footer-container .footer-text h3 {
    margin-top: 27px;
    margin-left: 17%;
    width: 65%;
    font-size: 8px;
    color: white;
}

.footer-container .footer-text h3:hover {
    color: rgb(197, 37, 25);
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
}

.footer-container .footer-heart img {
    width: 18px;
}


/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/


@media screen and (max-width: 1000px) { 

    .footer-container {
        margin-bottom: 60px;
    }

    .footer-container .footer-text {
        width: 800px;
    }

    .footer-container .footer-text h2 {
        font-size: 30px;
    }

    .footer-container .footer-heart img {
        width: 25px;
    }

    .footer-container .footer-text h3 {
        margin-top: 60px;
        margin-left: 10%;
        width: 80%;
        font-size: 15px;
        color: rgb(197, 37, 25);
    }
}