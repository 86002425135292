.pinwheel-container {
    background-color: rgb(66, 74, 64);
    border: 1px solid white;
    box-shadow: inset 0 0 58px #000000;
    position: relative;
}

.pinwheel-digit {
    font-size: 40px;
    padding: 20px;
    font-family: 'LCD', sans-serif;
    position: absolute;
    top: 0;
    color: #ff0000;
}

.pinwheel-shadow {
    font-size: 40px;
    padding: 20px;
    color: #980202;
    font-family: 'LCD', sans-serif;
    opacity: 25%;
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

@media screen and (max-width: 1000px) {

    .pinwheel-digit {
        font-size: 30px;
        padding: 15px;
    }

    .pinwheel-shadow {
        font-size: 30px;
        padding: 15px;
    }
}