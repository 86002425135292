.container {
    width: 1440px;
    max-width: 90%;
    margin: auto;
    padding: 0 16px;  
}

.container img {
    width: 32px;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
}

.container a:hover {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
}

header {
    background-color: #FFF;
    box-shadow: 0 0.02px 6px 0 #c74298;
    height: 90px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    font-family: 'Press Start 2P', cursive;
    z-index: 10;
}

header h1 {
    font-size: 24px;
    letter-spacing: 0.37px;
    color: rgb(1, 1, 59);
    padding-bottom: 6px;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
}

header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

header nav ul li {
    display: inline-block;
    margin-bottom: 8px;
}

header nav ul li .header-links {
    color: rgb(1, 1, 59);
    font-size: 13px;
    letter-spacing: 0.37px;
    margin-right: 24px;
    text-decoration: none;
}

header nav ul li .header-links:hover {
    color: rgb(243, 199, 239);
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
}

.heart-img {
    display: none;
}


/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

@media screen and (max-width: 1000px) {

    .container {
        width: 100%;
    }

    header {
        height: 250px;
        box-shadow: 0 0.02px 10px 0 #c74298;
    }

    .container img {
        padding-left: 30px;
        width: 150px;
    }

    header h1 {
        display: none;
    }

    .heart-img {
        display: contents;
    }

    header nav ul li .header-links {
        font-size: 22px;
        letter-spacing: 0.37px;
        margin-right: 40px;
    }
}