.upload-container {
    padding: 200px;
    padding-top: 160px;
    padding-bottom: 150px;
    background-color: rgb(225, 225, 225);
    font-family: 'Press Start 2P', cursive;
}

.game-border {
    border: 1px solid white;
}

.game-splash button{
    font-family: 'Press Start 2P', cursive;
    background-color: #FF6EC7;
}

.post {
    margin-top: 80px;
    border: 1.5px solid rgb(197, 37, 25);
    box-sizing: border-box;
    border-radius: 30px 30px 30px 30px;
    padding: 100px;
    padding-top: 60px;
    background-color: rgb(243, 199, 239);
}

.post .title-container {
    width: 200px;
    margin-right: 65%;
}

.post .title-container h1 {
    padding: 20px;
    padding-left: 0px;
    margin-left: 0px;
    font-size: 20px;
    font-family: 'Press Start 2P', cursive;
    width: 500px;
}

.post .title-container button {
    margin: 0;
    margin-top: 10px;
    margin-left: 8px;
    height: 38px;
    width: 135px;
    background-color: rgb(197, 37, 25);
    color: #FFF;
    font-size: 12px;
    letter-spacing: 0.35px;
    text-transform: uppercase;
    line-height: 21px;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
    font-family: 'Press Start 2P', cursive;
    position: relative;
    border-image-slice: 2;
    border-image-width: 4;
    border-image-source: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8" ?><svg version="1.1" width="5" height="5" xmlns="http://www.w3.org/2000/svg"><path d="M2 1 h1 v1 h-1 z M1 2 h1 v1 h-1 z M3 2 h1 v1 h-1 z M2 3 h1 v1 h-1 z" fill="rgb(33,37,41)" /></svg>');
    border-image-outset: 4;
}

.post .title-container input {
    font-family: 'Press Start 2P', cursive;
    color: rgb(1, 1, 59);
}

.post .title-container button:hover {
    color: rgb(197, 37, 25);
    background-color: #FFF;
}

.post .title-container button::after {
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    content: "";
    box-shadow: inset -4px -4px 0px 0px rgb(134, 24, 16);
}

.post .title-container button:hover::after {
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    content: "";
    box-shadow: inset -4px -4px 0px 0px #c5c3c3;
}

.post .title-container button:active::after {
    box-shadow: inset 4px 4px 0px 0px #c5c3c3;
}

::file-selector-button {
    margin: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 21px;
    padding-right: 21px;
    margin-right: 20px; 
    background-color: rgb(197, 37, 25);
    color: #FFF;
    font-size: 12px;
    letter-spacing: 0.35px;
    text-transform: uppercase;
    line-height: 21px;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
    font-family: 'Press Start 2P', cursive;
    border-image-slice: 2;
    border-image-width: 4;
    border-image-source: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8" ?><svg version="1.1" width="5" height="5" xmlns="http://www.w3.org/2000/svg"><path d="M2 1 h1 v1 h-1 z M1 2 h1 v1 h-1 z M3 2 h1 v1 h-1 z M2 3 h1 v1 h-1 z" fill="rgb(33,37,41)" /></svg>');
    border-image-outset: 4;
}


/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

@media screen and (max-width: 1000px) {

    .upload-container {
        padding: 80px;
        padding-top: 350px;
        padding-bottom: 350px;
    }

    .post {
        margin-top: 100px;
        border: 2.5px solid rgb(197, 37, 25);
        padding: 70px;
        padding-top: 60px;
        padding-bottom: 400px;
    }

    .post .title-container h1 {
        font-size: 50px;
        width: 700px;
        margin-top: 150px;
    }

    ::file-selector-button {
        margin: 8px;
        margin-top: 100px;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
        margin-right: 20px; 
        font-size: 30px;
        letter-spacing: 0.5px;
    }

    .post .title-container button {
        margin: 0;
        margin-top: 60px;
        margin-left: 8px;
        height: 85px;
        width: 430px;
        font-size: 30px;
        letter-spacing: 0.35px;
    }
}