.row {
    display: flex;
    flex-wrap: wrap;
}

.column{
    flex: 50%;
    padding: 80px;
    padding-bottom: 0;
    max-width: 100%;
}

.image-container {
    width: 100%;
}

.image-container img {
    max-width: 50%;
}

.column-container {
    display: flex;
    max-width: 100%;
}

.column-container .year-styling {
    width: 50%;
    color: rgb(1, 1, 59);
}

.column-container .year-styling hr {
    width: 80%;
    margin: 0;
    height: 2px;
    border-width: 0;
    background-color: rgb(210, 209, 209);
}