.img-of-day-container {
    padding: 130px;
    padding-top: 160px;
    padding-bottom: 150px;
    background-color: rgb(243, 199, 239);
    font-family: 'Press Start 2P', cursive;
    color: rgb(1, 1, 59)
}

.inner-container {
    padding: 60px;
    padding-top: 40px;
    padding-bottom: 100px;
    background-color: rgb(225, 225, 225);
    border: 2px solid rgb(197, 37, 25);
    box-sizing: border-box;
    border-radius: 30px 30px 30px 30px;
}

.img-of-day-container h1 {
    color: rgb(1, 1, 59);
    font-size: 35px;
}

.img-of-day-container .null-message {
    font-size: 15px;
    padding: 70px;
    padding-top: 120px;
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/


@media screen and (max-width: 1000px) { 

    .img-of-day-container {
        padding: 100px;
        padding-top: 350px;
        padding-bottom: 150px;
    }

    .inner-container {
        padding: 60px;
        padding-top: 40px;
        padding-bottom: 100px;
    }

    .img-of-day-container h1 {
        font-size: 37px;
        margin-top: 50px;
    }

    .img-of-day-container .null-message {
        font-size: 23px;
        padding-top: 120px;
        line-height: 40px;
    }
}