.img-of-day-container-full-gallery {
    padding: 130px;
    padding-top: 160px;
    padding-bottom: 250px;
    background-color: rgb(243, 199, 239);
    font-family: 'Press Start 2P', cursive;
    color: rgb(1, 1, 59)
}

.inner-container-full-gallery {
    padding: 60px;
    padding-top: 40px;
    padding-bottom: 100px;
    background-color: rgb(225, 225, 225);
    border: 2px solid rgb(197, 37, 25);
    box-sizing: border-box;
    border-radius: 30px 30px 30px 30px;
}

.img-of-day-container-full-gallery h1 {
    color: rgb(1, 1, 59);
    font-size: 22px;
    padding-bottom: 30px;
}

.row-full-gallery {
    display: flex;
    flex-wrap: wrap;
}

.column-full-gallery {
    flex: 20%;
    padding: 20px;
    max-width: 100%;
    padding-top: 30px;
}

.image-container-full-gallery {
    width: 100%;
}

.image-container-full-gallery img {
    max-width: 100%;
    z-index: 3;
}

.image-container-full-gallery img:hover {
    filter: blur(.3em) grayscale(100%);
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
}

.column-container-full-gallery {
    display: flex;
    max-width: 100%;
    justify-content: center;
    position: relative;
}

.image-container-full-gallery .img-hover {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    font-size: 20px;
    color: rgb(244, 11, 182);
}

.image-container-full-gallery img:hover {
    z-index: 0;
}


/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/


@media screen and (max-width: 1000px) {  

    .img-of-day-container-full-gallery {
        padding: 100px;
        padding-top: 360px;
        padding-bottom: 150px;
    }

    .img-of-day-container-full-gallery h1 {
        font-size: 33px;
        padding-bottom: 35px;
        line-height: 50px;
    }

    .column-full-gallery {
        flex: 50%;
        padding: 20px;
        max-width: 100%;
        padding-top: 50px;
    }

    .image-container-full-gallery .img-hover {
        top: 40px;
        left: 40px;
        z-index: 2;
        font-size: 30px;
    }
}

