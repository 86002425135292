.gallery-container .back-in-time {
    font-family: 'Marty', sans-serif;
    font-size: 200px;

    background: -webkit-linear-gradient( #c31e1b, #c31e1b, #c31e1b, #cf591e,#e1911b, #fae215, #fae215, #fae215);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    -webkit-text-stroke: 3.5px #7ed6fd;
}

.title-annimation {
    display: flex;
}

.btf-title-container {
    display: flex;
}

.gallery-container .in-time  {
    padding-top: 42px;
    font-family: 'Marty', sans-serif;
    font-size: 100px;
    background: -webkit-linear-gradient(  #c31e1b, #c31e1b, #c31e1b, #c31e1b,#cf591e,#cf591e, #e1911b, #fae215, #fae215, #fae215, #fae215);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    -webkit-text-stroke: 1.5px #7ed6fd;

    line-height: 50px;
}

.gallery-container .back-in-time img {
    transform: rotate(5deg);
    -webkit-filter: drop-shadow(6px 6px 0 #7ed6fd)
                  drop-shadow(-6px -6px 0 #7ed6fd);
    filter: drop-shadow(6px 6px 0 #7ed6fd) 
            drop-shadow(-6px -6px 0 #7ed6fd);
}

.clock-container {
    background-color: rgb(95, 95, 95);
    width: 85%;
    border: 2px solid #484747;
    margin: auto;
    margin-left: 40px;
    margin-bottom: 70px;
    padding: 20px;
}

.clock-container h5 {
    margin-top: 0px;
    padding: 5px;
    color: white;
    background-color: rgb(162, 11, 11);
    text-align: center;
    box-shadow: inset 0 0 5px rgb(112, 10, 10);
}

.clock-container h3 {
    margin-top: 0px;
    margin: auto;
    padding: 5px;
    width: 40%;
    color: white;
    background-color: black;
    text-align: center;
}

.clock-date {
    display: flex;
    margin-left: 40px;
}

.clock-day {
    margin: 30px;
    margin-bottom: 0;
}

.clock-month {
    margin: 30px;
    margin-bottom: 0;
}

.clock-year {
    margin: 30px;
    margin-bottom: 0;
}

.clock-day-digits {
    display: flex;
}

.clock-month-digits {
    display: flex;
}

.clock-year-digits {
    display: flex;
}

.led-container {
    padding-left: 15px;
}

.led-red {
    margin: 0 auto;
    margin-top: 20px;
    width: 24px;
    height: 24px;
    background-color: #F00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
    -webkit-animation: blinkRed 0.5s infinite;
    -moz-animation: blinkRed 0.5s infinite;
    -ms-animation: blinkRed 0.5s infinite;
    -o-animation: blinkRed 0.5s infinite;
    animation: blinkRed 0.5s infinite;
  }
  
  @-webkit-keyframes blinkRed {
      from { background-color: #F00; }
      50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
      to { background-color: #F00; }
  }
  @-moz-keyframes blinkRed {
      from { background-color: #F00; }
      50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
      to { background-color: #F00; }
  }
  @-ms-keyframes blinkRed {
      from { background-color: #F00; }
      50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
      to { background-color: #F00; }
  }
  @-o-keyframes blinkRed {
      from { background-color: #F00; }
      50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
      to { background-color: #F00; }
  }
  @keyframes blinkRed {
      from { background-color: #F00; }
      50% { background-color: #A00; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;}
      to { background-color: #F00; }
  }

  .led-red-off {
    margin: 0 auto;
    margin-top: 20px;
    width: 24px;
    height: 24px;
    background-color: #F00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
    background-color: rgb(130, 9, 9); box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #251414 0 -1px 9px;
  }

  .led-green {
    margin: 0 auto;
    margin-top: 20px;
    width: 24px;
    height: 24px;
    background-color: #ABFF00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
  }

  .led-green-off {
    margin: 0 auto;
    margin-top: 20px;
    width: 24px;
    height: 24px;
    background-color: #ABFF00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
    background-color: rgb(43, 110, 7); box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #0e180d 0 -1px 9px;
  }

  .metal {
    position: relative;
    margin: 40px auto;
    outline: none;
    text-shadow: hsla(0,0%,40%,.5) 0 -1px 0, hsla(0,0%,100%,.6) 0 2px 1px;
    
    background-color: rgb(95, 95, 95);
    box-shadow: inset hsla(0,0%,15%,  1) 0  0px 0px 4px, /* border */
      inset hsla(0,0%,15%, .8) 0 -1px 5px 4px, /* soft SD */
      inset hsla(0,0%,0%, .25) 0 -1px 0px 7px, /* bottom SD */
      inset hsla(0,0%,100%,.7) 0  2px 1px 7px, /* top HL */
      
      hsla(0,0%, 0%,.15) 0 -5px 6px 4px, /* outer SD */
      hsla(0,0%,100%,.5) 0  5px 6px 4px; /* outer HL */ 
    
    transition: color .2s;
  }

  .metal.linear {
    border-radius: .5em;
    background-image: -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0)   6%, hsla(0,0%,100%, .1) 7.5%),
      -webkit-repeating-linear-gradient(left, hsla(0,0%,  0%,0) 0%, hsla(0,0%,  0%,0)   4%, hsla(0,0%,  0%,.03) 4.5%),
      -webkit-repeating-linear-gradient(left, hsla(0,0%,100%,0) 0%, hsla(0,0%,100%,0) 1.2%, hsla(0,0%,100%,.15) 2.2%),
      
      linear-gradient(180deg, hsl(0,0%,37%)  0%, 
      hsl(0, 0%, 71%) 47%, 
      hsl(0, 0%, 62%) 53%,
      hsl(0, 0%, 27%)100%);
  }

.button-container {
    text-align: right;
    margin-right: 35px;
}

.button-container button {
    margin: 0 auto;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ff0000; 
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #251414 0 -1px 9px;
}

.button-container button:hover {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
}

.clock-container h6 {
    text-align: right;
    margin: auto;
    margin-right: 22.5px;
    padding-top: 10px;
    font-size: 8px;
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

@media screen and (max-width: 1000px) {

    .gallery-container .back-in-time {
        margin-top: 250px;
        width: 560px;
        font-size: 180px;
    }

    .btf-title-container {
        margin-bottom: 40px;
    }

    .gallery-container .in-time  {
        margin-top: 200px;
        font-size: 80px;
    }

    .clock-container {
        width: 93%;
        margin: auto;
        margin-bottom: 100px;
        padding: 20px;
    }

    .clock-date {
        margin-left: 0px;
        padding-top: 20px;
    }

    .clock-day {
        margin: 20px;
    }

    .clock-month {
        margin: 20px;
    }

    .clock-year {
        margin: 20px;
    }

    .clock-container h6 {
        margin-right: 15px;
        padding-top: 10px;
        font-size: 12px;
    }

    .clock-container h3 {
        margin-top: 0px;
        margin: auto;
        padding: 10px;
        width: 55%;
        color: white;
        background-color: black;
        text-align: center;
    }
}