.gallery-container {
    padding: 130px;
    padding-top: 160px;
    padding-bottom: 170px;
    background-color: #78bfeb;
    font-family: 'Press Start 2P', cursive;
    color: rgb(1, 1, 59)
}

.gallery-container .inner-container {
    padding: 60px;
    padding-top: 60px;
    padding-bottom: 70px;
    background-color: rgb(225, 225, 225);
    border: 2px solid rgb(250, 132, 208);
    box-sizing: border-box;
    border-radius: 30px 30px 30px 30px;
}

.gallery-container h1 {
    color: rgb(250, 132, 208);
    font-size: 22px;
}

.gallery-container .last-header {
    font-size: 16px;
    text-align: right;
    padding-top: 30px;
}

.gallery-container .inner-container .year-row {
    margin-top: 60px;
    display: flex;
    margin-bottom: 80px;
}

.gallery-container .inner-container .year-column {
    padding-top: 15px;
}

.gallery-container .inner-container .year-column .year-text {
    margin: 50px;
    color: rgb(1, 1, 59);
}

.gallery-container .inner-container .year-column .year-text {
    text-decoration: none;
}

.gallery-container .inner-container .year-column .year-text:hover {
    color: rgb(197, 37, 25);
    text-decoration: underline;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/


@media screen and (max-width: 1000px) { 

    .gallery-container {
        padding: 100px;
        padding-top: 350px;
        padding-bottom: 130px;
    }
    
    .gallery-container h1 {
        font-size: 35px;
        line-height: 40px;
    }

    .gallery-container .last-header {
        font-size: 22px;
        padding-top: 50px;
    }

    .gallery-container .inner-container .year-column .year-text {
        font-size: 30px;
    }

    .gallery-container .inner-container .year-column {
        padding-top: 30px;
    }
}