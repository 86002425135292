.login-container {
    margin-top: 50px;
    font-family: 'Press Start 2P', cursive;
}

.login-banner {
    display: flex;
    justify-content: center;
    background: rgb(254, 221, 253);
}

.login-banner img {
    width: 150px;
}

.login-container form {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    align-items: center;
}

.login-container form input {
    width: 300px;
    box-sizing: border-box;
    border-radius: 60px 60px 60px 60px;
    height: 30px;
    border: 1px solid rgb(1, 1, 59);
    color: rgb(1, 1, 59);
    padding-left: 22px;
    margin-top: 20px;
    font-family: 'Press Start 2P', cursive;
    font-size: 10px;
}

.login-container form input:hover {
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
}

.login-container .login-details {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.login-container .form-section {
    padding: 90px;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: rgba(200,200,200,0.18);
}

.login-container .login-button {
    height: 30px;
    width: 300px;
    box-sizing: border-box;
    border-radius: 60px 60px 60px 60px;
    background-color: rgb(197, 37, 25);
    border: 1px solid rgb(197, 37, 25);
    color: #FFF;
    font-family: 'Press Start 2P', cursive;
    font-size: 10px;
    letter-spacing: 0.35px;
    text-transform: uppercase;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
    margin-top: 20px;
}

.login-container .login-button:hover {
    color: rgb(197, 37, 25);
    border: 1px solid rgb(197, 37, 25);
    background-color: #FFF;
    border: 1px solid rgb(1, 1, 59);
}

.login-container h1 {
    text-align: center;
    font-family: 'Press Start 2P', cursive;
    font-size: 20px;
    margin-bottom: 20px;
}

.login-container h2 {
    margin-top: 30px;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    color: rgb(1, 1, 59);
}

.login-container .sign-up {
    height: 40px;
    width: 120px;
    box-sizing: border-box;
    border-radius: 60px 60px 60px 60px;
    background-color: #c74298;
    border: 1px solid #c74298;
    color: #FFF;
    font-size: 8px;
    line-height: 15px;
    font-family: 'Press Start 2P', cursive;
    letter-spacing: 0.35px;
    text-transform: uppercase;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzElEQVRYR+2X0Q6AIAhF5f8/2jYXZkwEjNSVvVUjDpcrGgT7FUkI2D9xRfQETwNIiWO85wfINfQUEyxBG2ArsLwC0jioGt5zFcwF4OYDPi/mBYKm4t0U8ATgRm3ThFoAqkhNgWkA0jJLvaOVSs7j3qMnSgXWBMiWPXe94QqMBMBc1VZIvaTu5u5pQewq0EqNZvIEMCmxAawK0DNkay9QmfFNAJUXfgGgUkLaE7j/h8fnASkxHTz0DGIBMCnBeeM7AArpUd3mz2x3C7wADglA8BcWMZhZAAAAAElFTkSuQmCC) 14 0,pointer;
    margin-top: 40px;
}

.login-container .sign-up:hover {
    color: #c74298;
    border: 1px solid #c74298;
    background-color: #FFF;
    border: 1px solid rgb(1, 1, 59);
}

.login-container .forgot-password {
    display: flex;
    justify-content: center;
}

.forgot-password .to-login-page {
    margin-top: 40px;
    font-size: 10px;
    color: rgb(1, 1, 59);
}

.forgot-password .to-login-page:hover {
    color: #c74298;
}

/* **********************************
******  CSS MEDIA QUERIES ********* 
************************************/

@media screen and (max-width: 1000px) {

    .login-container {
        margin-top: 150px;
    }

    .login-banner img {
        width: 50%;
    }
    
    .login-container .login-details {
        margin-top: 0;
        width: 100%;
    }
    
    .login-container .form-section {
        padding: 90px;
        padding-top: 150px;
        padding-bottom: 350px;
        width: 100%;
    }
    
    .login-container form input {
        width: 650px;
        height: 80px;
        padding-left: 22px;
        margin-top: 30px;
        font-size: 20px;
    }

    .login-container h1 {
        font-size: 70px;
        margin-bottom: 50px;
        padding-top: 40px;
    }

    .login-container .login-button {
        height: 80px;
        width: 650px;
        font-size: 20px;
        letter-spacing: 0.5px;
        margin-top: 45px;
        align-self: center;
    }

    .login-container .sign-up {
        height: 80px;
        width: 400px;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.5px;
        margin-top: 40px;
    }
}